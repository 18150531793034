export const setConstants = (data) => {
    return (dispatch) => {
        dispatch({
            type: 'setConstants',
            payload: data
        });
    }
}

export const setApiCache = (data) => {
    return (dispatch) => {
        dispatch({
            type: 'setApiCache',
            payload: data
        });
    }
}