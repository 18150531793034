/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { getCompanyConfig } from '../../app/modules/Unr/_unr.service';
import { ForgotPassword } from './components/ForgotPassword';
import LoginBorrower from './components/LoginBorrower';
import ProgramSelector from './components/ProgramSelector';
import { Registration } from './components/Registration';
import { Login } from './components/Login';

const AuthLayout = () => {
    const [logo, setLogo] = useState('');

    const initLogo = async () => {
        const response = await getCompanyConfig();
        const logoObj = response?.logos?.[0];
        if(logoObj?.file_url){
            setLogo(logoObj?.file_url);
        } else {
            setLogo(toAbsoluteUrl('/media/logos/default-dark.svg'));
        }
    };

    useEffect(() => {
        initLogo();
        return () => {};
    }, []);

    useEffect(() => {
        if (!logo) return;
        
        const img = new Image();
        img.crossOrigin = "Anonymous"; // Allow cross-origin images
        img.src = logo;
        
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
    
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);
    
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixels = imageData.data;
    
          let totalBrightness = 0;
          const pixelCount = pixels.length / 4;
    
          for (let i = 0; i < pixels.length; i += 4) {
            const r = pixels[i];
            const g = pixels[i + 1];
            const b = pixels[i + 2];
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            totalBrightness += brightness;
          }
    
          const avgBrightness = totalBrightness / pixelCount;
          const isLogoDark = avgBrightness < 128
          if(isLogoDark){
            document.body.style.backgroundImage = 'url(/assets/bg-light.jpg)';
          } else {
            document.body.style.backgroundImage = 'url(/assets/bg-dark.jpeg)';
          }
        };
    }, [logo]);

    return (
        <div className='d-flex flex-column flex-column-fluid flex-lg-row'>
            <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
                <div className='d-flex flex-center flex-lg-start flex-column'>
                    <a
                        href='/metronic8/demo1/index.html'
                        className='mb-7'>
                        <img
                            alt='Logo'
                            className='h-60px'
                            src={logo}
                        />
                    </a>

                    <h2 className='text-white fw-normal m-0'>Credit on the go for Supply Chain</h2>
                </div>
            </div>

            <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
                <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20'>
                    <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20'>
                        <Outlet />
                    </div>

                    <div className='d-flex flex-stack px-lg-10'>
                        <div className='me-0'>Copyright © {new Date().getFullYear()}. Crego.</div>

                        <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                            <a
                                href='https://crego.io/'
                                rel='noreferrer'
                                target='_blank'>
                                Terms
                            </a>
                            <a
                                href='https://crego.io/'
                                rel='noreferrer'
                                target='_blank'>
                                Contact Us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route
                path='login'
                element={<Login />}
            />
            <Route
                path='login/borrower'
                element={<LoginBorrower />}
            />
            <Route
                path='programs'
                element={<ProgramSelector />}
            />
            <Route
                path='registration'
                element={<Registration />}
            />
            <Route
                path='forgot-password'
                element={<ForgotPassword />}
            />
            <Route
                index
                element={<Login />}
            />
        </Route>
    </Routes>
);

export { AuthPage };
