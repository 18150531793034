import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeProfile, getProfiles } from '../../../app/modules/Identity/_identity.service';
import { useAuth } from '../core/Auth';
import { getBorrowerByToken } from '../core/_requests';
import { listConstants } from '../../../app/modules/Constants/_constants.service';
import { bindActionCreators } from '@reduxjs/toolkit';
import { creators } from '../../../app/state';
import { useDispatch } from 'react-redux';
import { getLead, getLeadProgramAccounts } from '../../../app/modules/Crm/_crm.service';

const ProgramSelector = () => {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { auth, setCurrentUser, saveAuth } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setConstants } = bindActionCreators(creators, dispatch);

    const initConstants = async () => {
        const data = await listConstants();
        setConstants(data);
    };

    const initProfiles = async () => {
        setLoading(true);
        const response = await getProfiles();
        if (response.length === 1) {
            switchProfile(response[0]);
        } else if (response.length === 0) {
            window.location.href = '/';
        }
        setProfiles(response);
        setLoading(false);
    };

    const switchProfile = async (profile) => {
        const payload = {
            lead_id: profile.lead_id,
            program_id: profile.program_id,
        };
        const response = await changeProfile(payload);
        if (response?.access_token) {
            saveAuth({ access: response.access_token, refresh: response.refresh_token });
            const { data: user } = await getBorrowerByToken(auth.access, true);
            localStorage.setItem(`user_type`, 'borrower');

            const tmp = {};
            const serviceAccounts = await getLeadProgramAccounts(
                payload.lead_id,
                payload.program_id
            );
            Object.entries(serviceAccounts).forEach(([key, value]) => {
                if (value?.length) {
                    tmp[key] = value[0];
                }
            });
            user.service_accounts = tmp;
            user.user = await getLead(user.lead_id);
            user.user_type = 'borrower';
            setCurrentUser(user);
            navigate('/');
        }
    };

    useEffect(() => {
        initProfiles();
    }, []);
    return (
        <>
            <div className='text-center mb-10'>
                <h1 className='text-gray-900 mb-3'>Welcome</h1>
                <div className='fw-bold text-gray-900 fs-3 mt-2'>{profiles?.[0]?.name}</div>
                <div className='text-muted fw-semibold fs-5 mt-10 mb-5'>
                    Select a program to login
                </div>
            </div>
            {profiles.map((profile) => {
                return (
                    <div
                        role='button'
                        onClick={() => switchProfile(profile)}
                        key={profile.account_ref_id}
                        className='d-flex justify-content-between align-items-center rounded py-5 px-5 bg-light-info w-100 mb-2'>
                        <div>
                            <h3>{profile?.program?.name}</h3>
                            <p className='mb-0'>{profile?.program.ref_id}</p>
                        </div>
                        <i className='fs-2x bi-arrow-right'></i>
                    </div>
                );
            })}
        </>
    );
};

export default ProgramSelector;
