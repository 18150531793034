import { tabularizeIgnore } from '../consts/trans';

export const fixObject = (obj) => {
    Object.entries(obj).forEach(([key, val]) => {
        if (typeof val === 'object') {
            const keyType = typeof Object.keys(val)[0];
            if (keyType === 'string' || keyType === 'number') {
                obj[key] = Object.values(val);
            } else {
                obj = { ...obj, ...fixObject(val) };
            }
        }
    });
    return obj;
};

export const searchObjectByKey = (obj, search) => {
    const tmp = {};
    Object.keys(obj).forEach((key) => {
        if (key.indexOf(search) !== -1) {
            tmp[key] = obj[key];
        }
    });
    return tmp;
};

export const updateObject = (obj, path, val) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
    return convertNumericKeyObjectsToArray(obj);
};

export const removeEmpty = (obj) => {
    const tmp = {};
    Object.entries(obj).map(([key, value]) => {
        if (value) {
            tmp[key] = value;
        }
    });
    return tmp;
};

export const filterObject = (obj, keys) => {
    return Object.keys(obj)
        .filter((key) => keys.indexOf(key) !== -1)
        .reduce((cur, key) => {
            return Object.assign(cur, { [key]: obj[key] });
        }, {});
};

export const flattenExpanded = (obj) => {
    let tmp = { ...obj };
    Object.entries(tmp).forEach(([k, v]) => {
        if (v && typeof v === 'object') {
            Object.entries(v).forEach(([sk, sv]) => {
                if (typeof sv === 'object') {
                    tmp = { ...tmp, ...flattenExpanded({ [sk]: sv }) };
                } else {
                    tmp[`${k}_${sk}`] = sv;
                }
            });
            if (v.id) {
                tmp[k] = v.id;
            }
        }
    });
    return tmp;
};

export const tabularize = (obj) => {
    let tmp = [];
    if (!obj) {
        return tmp;
    }
    Object.entries(obj).forEach(([k, v]) => {
        if (tabularizeIgnore.indexOf(k) !== -1 || !v) {
            return;
        }
        if (Array.isArray(v) && v.length > 0 && typeof v[0] !== 'object') {
            tmp.push({ key: k, value: v.join(', ') });
        } else if (Array.isArray(v)) {
            if (v.length > 0) {
                tmp.push({ key: 'heading', value: k });
                v.forEach((row) => {
                    tmp = [...tmp, ...tabularize(row)];
                });
                tmp.push({ key: 'separator' });
            }
        } else if (typeof v === 'object') {
            tmp.push({ key: 'heading', value: k });
            tmp = [...tmp, ...tabularize(v)];
            tmp.push({ key: 'separator' });
        } else {
            tmp.push({
                key: k,
                value: v,
            });
        }
    });
    console.log(tmp);
    return tmp;
};

export const deleteKeys = (obj, keys) => {
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            for (let i = 0; i < obj.length; i++) {
                obj[i] = deleteKeys(obj[i], keys);
            }
        } else {
            for (let prop in obj) {
                if (keys.includes(prop)) {
                    delete obj[prop];
                } else {
                    obj[prop] = deleteKeys(obj[prop], keys);
                }
            }
        }
    }
    return obj;
};

export const convertNumericKeyObjectsToArray = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    // Check if all keys are numeric
    const keys = Object.keys(obj);
    const allNumericKeys = keys.every((key) => !isNaN(key));

    if (allNumericKeys) {
        // Convert object to array
        const newArray = [];
        keys.forEach((key) => {
            newArray[parseInt(key, 10)] = convertNumericKeyObjectsToArray(obj[key]);
        });
        return newArray;
    }

    // Recursively apply the conversion to each property
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            obj[key] = convertNumericKeyObjectsToArray(obj[key]);
        }
    }

    return obj;
};

export const extractKeys = (obj, prefix = '') => {
    let keys = [];
    for (let key in obj) {
        const newPrefix = prefix ? `${prefix}.${key}` : key;
        if (obj[key]?.type) {
            keys.push(newPrefix);
            continue;
        }

        keys.push(newPrefix);
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            keys = keys.concat(extractKeys(obj[key], newPrefix));
        }
    }
    return keys;
};

export const extractKeysFromJsonSchema = (schema, parentKey = '') => {
    let keys = [];

    if (schema && schema.properties) {
        Object.keys(schema.properties).forEach((key) => {
            const property = schema.properties[key];
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            keys.push(fullKey);
            if (property.type === 'object' && property.properties) {
                keys = keys.concat(extractKeysFromJsonSchema(property, fullKey));
            }
        });
    }

    keys = keys.filter((item) => ['if', 'then', 'required'].indexOf(item) === -1);
    return keys;
};
