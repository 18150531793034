export const BASE_URL =
    (process.env.REACT_APP_API_ROOT_UNR || process.env.REACT_APP_API_ROOT_DEFAULT) + '/unr/';

export const urls = {
    configs: BASE_URL + 'configs/',
    config_details: BASE_URL + 'configs/:id/',

    config_companies: BASE_URL + 'configs/:id/companies/',

    plugins: BASE_URL + 'plugins/',
    plugin_details: BASE_URL + 'plugins/:id/',

    documents: BASE_URL + 'documents/',
    document_details: BASE_URL + 'documents/:id/',

    companies: BASE_URL + 'companies/',
    company_details: BASE_URL + 'companies/:id/',
    company_details_toggle_status: BASE_URL + 'companies/:id/toggle_status',
    company_details_info: BASE_URL + 'companies/:id',
    company_gst_details: BASE_URL + 'companies/:id/pan_to_gst_details',

    banks: BASE_URL + 'banks/',
    bank_details: BASE_URL + 'banks/:id',

    company_plugins: BASE_URL + 'company_plugins/',
    company_plugin_details: BASE_URL + 'company_plugins/:id/',

    programs: BASE_URL + 'programs/',
    program_details: BASE_URL + 'programs/:id/',
    program_companies: BASE_URL + 'programs/:id/companies/',
    program_config: BASE_URL + 'programs/:program_id/active_config/',
    program_bids: BASE_URL + 'programs/:id/bids/',
    program_partners: BASE_URL + 'programs/:id/partners/',
    program_bid_detail: BASE_URL + 'programs/:program/bids/:id/',
    program_bid_approve: BASE_URL + 'programs/:program/bids/:id/approve_bid/',
    program_bid_reject: BASE_URL + 'programs/:program/bids/:id/reject_bid/',

    partners: BASE_URL + 'partners/',

    program_eligible_banks: BASE_URL + 'programs/:account_program_id/eligible_banks/',
    configured_values: BASE_URL + 'programs/:account_program_id/configured_values/',
    topup_program_eligible_banks: BASE_URL + 'programs/:topup_account_program_id/eligible_banks/',

    vendors: BASE_URL + 'vendors/',
    vendor_details: BASE_URL + 'vendors/:id/',

    program_pwa_config: BASE_URL + 'programs/pwa_config/no_auth/',
};

export const program_flow = {
    pending: [
        {
            status: 'active',
            label: 'Activate',
        },
    ],
    active: [{ status: 'inactive', label: 'Deactivate' }],
    inactive: [{ status: 'active', label: 'Activate' }],
};

export const services = ['crm', 'los', 'disburse', 'collect', 'legal', 'pwa', 'engine'];
