/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthPage, Logout, useAuth } from '../../_mApp/auth';
import { ErrorsPage } from '../../_mApp/errors/ErrorsPage';
import { App } from '../App';
import { ROUTE_URLS } from '../consts/route_urls';
import { store } from '../state/store';
import { PrivateRoutes } from './PrivateRoutes';

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
    const { currentUser } = useAuth();
    const [redirectURL, setRedirectURL] = useState();
    let home = ROUTE_URLS.dashboard;
    const userType = localStorage.getItem('user_type');
    if (userType === 'borrower') {
        home = ROUTE_URLS.master + currentUser?.lead_id;
    }

    useEffect(() => {
        const currentURL = new URL(window.location.href);
        setRedirectURL(currentURL.searchParams.get('redirect'));
    }, [window.location.href]);

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route
                    element={
                        <Provider store={store}>
                            <App />
                        </Provider>
                    }>
                    <Route
                        path='error/*'
                        element={<ErrorsPage />}
                    />
                    <Route
                        path='logout'
                        element={<Logout />}
                    />
                    {currentUser ? (
                        <>
                            <Route
                                path='/*'
                                element={<PrivateRoutes />}
                            />
                            <Route
                                index
                                element={<Navigate to={home} />}
                            />
                        </>
                    ) : (
                        <>
                            <Route
                                path='auth/*'
                                element={<AuthPage />}
                            />
                            <Route
                                path='*'
                                element={
                                    <Navigate
                                        to={ROUTE_URLS.auth + `?redirect=${window.location.href}`}
                                    />
                                }
                            />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export { AppRoutes };
