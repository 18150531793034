export const ROUTES = {
    dashboard: { url: '/dashboard', title: 'Dashboard' },
    reports: { url: '/reports', title: 'Download Reports' },

    crm_borrowers: { url: '/crm/borrowers', title: 'Borrowers' },
    crm_borrowers_kyc_pending: {
        url: '/crm/borrowers/kyc_pending',
        title: 'KYC Pending Borrowers',
    },
    crm_borrowers_bre_pending: {
        url: '/crm/borrowers/bre_pending',
        title: 'BRE Pending Borrowers',
    },
    crm_borrowers_pending_activation: {
        url: '/crm/borrowers/activation',
        title: 'Pending Activation Borrowers',
    },

    crm_approvals_kyc_pending: {
        url: '/crm/approvals/kyc/pending',
        title: 'Pending KYC Approvals',
    },
    crm_approvals_kyc_approved: {
        url: '/crm/approvals/kyc/approved',
        title: 'Approved KYC Approvals',
    },
    crm_approvals_kyc_rejected: {
        url: '/crm/approvals/kyc/rejected',
        title: 'Rejected KYC Approvals',
    },
    crm_approvals_bre_pending: {
        url: '/crm/approvals/bre/pending',
        title: 'Pending BRE Approvals',
    },
    crm_approvals_bre_approved: {
        url: '/crm/approvals/bre/approved',
        title: 'Approved BRE Approvals',
    },
    crm_approvals_bre_rejected: {
        url: '/crm/approvals/bre/rejected',
        title: 'Rejected BRE Approvals',
    },

    los_pending_underwriting: {
        url: '/los/accounts/pending_underwriting',
        title: 'Pending Underwriting Accounts',
    },
    los_signing: { url: '/los/accounts/signing', title: 'Pending Signing' },
    los_mandates: { url: '/los/accounts/mandates', title: 'Pending Mandates' },
    los_pdcs: { url: '/los/accounts/pdcs', title: 'Pending PDC Upload' },
    los_fee: { url: '/los/accounts/fee', title: 'Pending Fee' },
    los_insurance: { url: '/los/accounts/insurance', title: 'Pending Insurance' },

    los_approvals_credit_pending: {
        url: '/los/approvals/credit/pending',
        title: 'Pending Credit Approvals',
    },
    los_approvals_credit_approved: {
        url: '/los/approvals/credit/approved',
        title: 'Approved Credit Approvals',
    },
    los_approvals_credit_rejected: {
        url: '/los/approvals/credit/rejected',
        title: 'Rejected Credit Approvals',
    },
    los_approvals_activation_pending: {
        url: '/los/approvals/activation/pending',
        title: 'Pending Activation Approvals',
    },
    los_approvals_activation_approved: {
        url: '/los/approvals/activation/approved',
        title: 'Approved Activation Approvals',
    },
    los_approvals_activation_rejected: {
        url: '/los/approvals/activation/rejected',
        title: 'Rejected Activation Approvals',
    },

    lms_topups: { url: '/disbursal/topups', title: 'Drawdowns' },
    lms_payouts: { url: '/disbursal/payouts', title: 'Payouts' },
    lms_records: { url: '/accounting/records', title: 'Repayments' },
    lms_excess_balance: { url: '/disbursal/excess_balance/cashback', title: 'Excess Balance' },
    lms_demand_sheet: { url: '/accounting/demand-sheet', title: 'Demand Sheet' },

    lms_banking_statements: { url: '/lms/banking/statements', title: 'Statements' },
    lms_banking_transactions: { url: '/lms/banking/transactions', title: 'Transactions' },
    lms_suspense_balances: { url: '/lms/banking/suspense-balances', title: 'Suspense Balances' },
    lms_unmatched_entries: { url: '/lms/banking/unmatched-entries', title: 'Unmatched Entries' },

    lms_approvals_topup_pending: {
        url: '/disbursal/approvals/topup/pending',
        title: 'Pending Topup Approvals',
    },
    lms_approvals_topup_approved: {
        url: '/disbursal/approvals/topup/approved',
        title: 'Approved Topup Approvals',
    },
    lms_approvals_topup_rejected: {
        url: '/disbursal/approvals/topup/rejected',
        title: 'Rejected Topup Approvals',
    },
    lms_approvals_topup_anomaly_pending: {
        url: '/disbursal/approvals/topup_anomaly/pending',
        title: 'Pending Topup Anomaly Approvals',
    },
    lms_approvals_topup_anomaly_approved: {
        url: '/disbursal/approvals/topup_anomaly/approved',
        title: 'Approved Topup Anomaly Approvals',
    },
    lms_approvals_topup_anomaly_rejected: {
        url: '/disbursal/approvals/topup_anomaly/rejected',
        title: 'Rejected Topup Anomaly Approvals',
    },
    lms_approvals_payout_pending: {
        url: '/disbursal/approvals/payout/pending',
        title: 'Pending Payout Approvals',
    },
    lms_approvals_payout_approved: {
        url: '/disbursal/approvals/payout/approved',
        title: 'Approved Payout Approvals',
    },
    lms_approvals_payout_rejected: {
        url: '/disbursal/approvals/payout/rejected',
        title: 'Rejected Payout Approvals',
    },
    lms_approvals_record_pending: {
        url: '/accounting/approvals/record/pending',
        title: 'Pending Repayment Approvals',
    },
    lms_approvals_record_approved: {
        url: '/accounting/approvals/record/approved',
        title: 'Approved Repayment Approvals',
    },
    lms_approvals_record_rejected: {
        url: '/accounting/approvals/record/rejected',
        title: 'Rejected Repayment Approvals',
    },

    lms_gl_accounts: { url: '/accounting/gl/accounts', title: 'GL Accounts' },
    lms_gl_transactions: { url: '/accounting/gl/transactions', title: 'GL Transactions' },

    admin_company: { url: '/admin/company', title: 'My Company' },
    admin_users: { url: '/admin/users', title: 'Users' },
    admin_user_roles: { url: '/admin/users/roles', title: 'Roles' },
    admin_companies: { url: '/admin/companies', title: 'Companies' },
    admin_programs: { url: '/admin/programs', title: 'Programs' },
    admin_configs: { url: '/admin/configs', title: 'Configurations' },
    admin_configs_system: { url: '/admin/configs/system', title: 'System Configurations' },
    admin_plugins: { url: '/admin/plugins', title: 'Plugins' },
    admin_templates: { url: '/admin/templates', title: 'Templates' },
    admin_templates_system: { url: '/admin/templates/system', title: 'System Templates' },
};
