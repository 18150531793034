const BASE_URL =
    (process.env.REACT_APP_API_ROOT_LMS || process.env.REACT_APP_API_ROOT_DEFAULT) + '/lms/';

export const urls = {
    ledgers_report: BASE_URL + 'ledgers/reports/',
    upcoming_dues_report: BASE_URL + 'loans/upcoming_dues/',
    debtors_report: BASE_URL + 'debtors_reports/',

    accounts: BASE_URL + 'accounts/',
    account_details: BASE_URL + 'accounts/:id/',
    account_summary: BASE_URL + 'accounts/:id/summary/',
    account_soa: BASE_URL + 'accounts/:id/soa/',
    account_interest_certificate: BASE_URL + 'accounts/:id/interest_certificate/',
    account_disbursement_advice: BASE_URL + 'accounts/:id/disbursement_advice/',
    account_write_off: BASE_URL + 'accounts/:id/writeoff',

    loans: BASE_URL + 'loans/',
    loan_details: BASE_URL + 'loans/:id',
    loan_summary: BASE_URL + 'loans/:id/summary/',
    loan_create_disbursement: BASE_URL + 'loans/create_disbursement',
    loans_mark_running: BASE_URL + 'loans/:id/mark_running',
    loans_mark_closed: BASE_URL + 'loans/:id/mark_closed',
    loans_revert: BASE_URL + 'loans/:id/revert',
    loans_excess_balance: BASE_URL + 'loans/excess_balance',
    loans_refunds: BASE_URL + 'loans/:loan_id/refunds',
    loans_adjustments: BASE_URL + 'loans/:loan_id/adjustments',

    line_details: BASE_URL + 'line_details/:id/',
    refresh_line_details: BASE_URL + 'accounts/:id/update_line_detail/',
    loan_data: BASE_URL + 'loan_data/:id',

    records: BASE_URL + 'records/',
    records_unmatched: BASE_URL + 'records/unmatched/',
    records_mark_processed: BASE_URL + 'records/:id/mark_processed',
    records_revert: BASE_URL + 'records/:id/revert',
    records_refunds: BASE_URL + 'records/:id/refunds',
    records_adjustments: BASE_URL + 'records/:id/adjustments',
    records_bulk_create: BASE_URL + 'records/bulk_create',

    ledger: BASE_URL + 'ledgers/',
    ledger_download_excel: BASE_URL + 'ledgers/download_excel/',
    ledger_run_account_summary: BASE_URL + 'ledgers/run_account_summary/',

    tasks: BASE_URL + 'tasks/',
    task_details: BASE_URL + 'tasks/:id/',

    reports_roi_computation: BASE_URL + 'reports/roi_computation/',
    reports_customer_portfolio: BASE_URL + 'reports/customer_portfolio/',
    reports_excess_balance: BASE_URL + 'reports/excess_balance/',
    reports_repayment_advice: BASE_URL + 'reports/repayment_advice/',
    reports_debtor_report: BASE_URL + 'reports/debtor/',
    reports_partner_payout: BASE_URL + 'reports/partner_payout/',

    dashboard_summary: BASE_URL + 'dashboard/summary/',
    dashboard_repayments: BASE_URL + 'dashboard/repayments/',
    dashboard_disbursements: BASE_URL + 'dashboard/disbursements/',

    settle_to_a_loan: BASE_URL + 'records/:id/settlement',
    cashback_download_excel: BASE_URL + 'loans/export/',
};
