import React from 'react';
import { useIntl } from 'react-intl';
import { KTIcon } from '../../../helpers';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { ROUTES } from '../../../../app/consts/routes';

export function AsideMenuMain() {
    const intl = useIntl();

    return (
        <>
            <AsideMenuItem
                to={ROUTES.dashboard.url}
                icon='color-swatch'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
                to='/builder'
                icon='switch'
                title='Layout Builder'
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        Crafted
                    </span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/crafted/pages'
                title='Pages'
                fontIcon='bi-archive'
                icon='element-plus'>
                <AsideMenuItemWithSub
                    to='/crafted/pages/profile'
                    title='Profile'
                    hasBullet={true}>
                    <AsideMenuItem
                        to='/crafted/pages/profile/overview'
                        title='Overview'
                        hasBullet={true}
                    />
                    <AsideMenuItem
                        to='/crafted/pages/profile/projects'
                        title='Projects'
                        hasBullet={true}
                    />
                    <AsideMenuItem
                        to='/crafted/pages/profile/campaigns'
                        title='Campaigns'
                        hasBullet={true}
                    />
                    <AsideMenuItem
                        to='/crafted/pages/profile/documents'
                        title='Documents'
                        hasBullet={true}
                    />
                    <AsideMenuItem
                        to='/crafted/pages/profile/connections'
                        title='Connections'
                        hasBullet={true}
                    />
                </AsideMenuItemWithSub>

                <AsideMenuItemWithSub
                    to='/crafted/pages/wizards'
                    title='Wizards'
                    hasBullet={true}>
                    <AsideMenuItem
                        to='/crafted/pages/wizards/horizontal'
                        title='Horizontal'
                        hasBullet={true}
                    />
                    <AsideMenuItem
                        to='/crafted/pages/wizards/vertical'
                        title='Vertical'
                        hasBullet={true}
                    />
                </AsideMenuItemWithSub>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/crafted/accounts'
                title='Accounts'
                icon='profile-circle'
                fontIcon='bi-person'>
                <AsideMenuItem
                    to='/crafted/account/overview'
                    title='Overview'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/account/settings'
                    title='Settings'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/error'
                title='Errors'
                fontIcon='bi-sticky'
                icon='cross-circle'>
                <AsideMenuItem
                    to='/error/404'
                    title='Error 404'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/error/500'
                    title='Error 500'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
                to='/crafted/widgets'
                title='Widgets'
                icon='element-11'
                fontIcon='bi-layers'>
                <AsideMenuItem
                    to='/crafted/widgets/lists'
                    title='Lists'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/widgets/statistics'
                    title='Statistics'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/widgets/charts'
                    title='Charts'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/widgets/mixed'
                    title='Mixed'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/widgets/tables'
                    title='Tables'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/crafted/widgets/feeds'
                    title='Feeds'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
                </div>
            </div>
            <AsideMenuItemWithSub
                to='/apps/chat'
                title='Chat'
                fontIcon='bi-chat-left'
                icon='message-text-2'>
                <AsideMenuItem
                    to='/apps/chat/private-chat'
                    title='Private Chat'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/apps/chat/group-chat'
                    title='Group Chart'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='/apps/chat/drawer-chat'
                    title='Drawer Chart'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>
            <AsideMenuItem
                to='/apps/user-management/users'
                icon='shield-tick'
                title='User management'
                fontIcon='bi-layers'
            />
            <div className='menu-item'>
                <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                </div>
            </div>
        </>
    );
}
