import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { beautify } from './str';

export const alertWarning = (text, title = 'Warning!') => {
    return showAlert('warning', title, text);
};

export const alertSuccess = (text, title = 'Success!') => {
    return showAlert('success', title, text);
};

export const alertError = (text, title = 'Error!') => {
    return showAlert('error', title, text);
};

export const toastWarning = (text) => {
    return showToast('warn', text);
};

export const toastSuccess = (text) => {
    return showToast('success', text);
};

export const toastError = (text) => {
    return showToast('error', text);
};

export const showToast = (type, text) => {
    toast[type](text);
};

export const showAlert = (icon, title, message, toast = false) => {
    let html = '';
    if (Array.isArray(message)) {
        html += '<table class="table table-striped text-start g-5 gs-5"><tbody>';
        if (message.length === 0) {
            return;
        }
        message.forEach((row) => {
            if (row.reference && row.error) {
                html +=
                    '<tr><td style="width: 10rem" class="text-danger p-5">' +
                    row.reference +
                    '</td><td class="p-5">' +
                    row.error +
                    '</td></tr>';
            } else if (typeof row === 'object') {
                html += `<tr>`;
                html += `<td style="width: 200px" class="text-danger">${row.head}</td>`;
                row.errors.forEach((error) => {
                    html += `<td>${error}</td>`;
                });
                html += `</tr>`;
            } else {
                html += '<tr><td style="width: 10rem" class="text-danger">' + row + '</td></tr>';
            }
        });
        html += '</tbody></table>';
    } else if (typeof message === 'object' && Object.keys(message).length > 0) {
        html += '<table class="table table-striped text-start"><tbody>';
        Object.entries(message).forEach(([key, value]) => {
            html +=
                '<tr><td style="width: 10rem" class="text-danger p-5">' +
                beautify(key) +
                '</td><td class="p-5">' +
                value +
                '</td></tr>';
        });
        html += '</tbody></table>';
    }
    if (html || message) {
        Swal.fire({
            icon,
            title,
            html: html || message,
            width: html ? '50rem' : '32rem',
            toast,
            position: toast ? 'bottom-end' : 'center',
        });
    }
};

export const confirm = (title, message, callback) => {
    Swal.fire({
        title: title,
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
    }).then((res) => {
        if (res.isConfirmed) {
            callback();
        }
    });
};
