import moment from 'moment';
import { plural, singular } from './str';

export const dateFromEpoch = (value, format = 'Do MMM YYYY') => {
    if (!value) {
        return '';
    }
    const date = moment.unix(value);
    return date.format(format);
};

export const dateTimeFromEpoch = (value, format = 'Do MMM YYYY, HH:mm') => {
    if (!value) {
        return '';
    }
    return moment.unix(value).format(format);
};

export const date = (value, format, sourceFormat) => {
    if (!value) {
        return '';
    }
    const date = moment(value, sourceFormat || '');
    return date.format(format || 'Do MMM YYYY');
};

export const dateTime = (value, format, sourceFormat) => {
    if (!value) {
        return '';
    }
    return moment(value, sourceFormat || '').format(format || 'Do MMM YYYY, HH:mm');
};

export const relativeDateTimeFromEpoch = (value) => {
    if (!value) {
        return '';
    }
    return moment.unix(value).fromNow();
};

export const days = (from, to, format = { from: 'YYYY-MM-DD', to: 'YYYY-MM-DD' }) => {
    to = to ? moment(to, format.to) : moment();
    if (!from || !to) {
        return '';
    }
    from = moment(from, format.from);
    return moment(to).diff(moment(from), 'days');
};

export const beautifyDuration = (input, unit, daysInMonth = 30) => {
    const result = [];
    if (unit === 'months') {
        const years = Math.floor(input / 12);
        const months = input % 12;
        if (years) {
            result.push(`${years} ${plural('years', years)}`);
        }
        if (months) {
            result.push(`${months} ${plural('months', months)}`);
        }
    } else if (unit === 'days') {
        let months = Math.floor(input / daysInMonth);
        const days = input % daysInMonth;
        if (months >= 12) {
            const years = Math.floor(months / 12);
            months -= years * 12;
            result.push(`${years} ${plural('years', years)}`);
        }
        if (months) {
            result.push(`${months} ${plural('months', months)}`);
        }
        if (days) {
            result.push(`${days} ${plural('days', days)}`);
        }
    } else if (unit === 'weeks') {
        return beautifyDuration(input * 7, 'days');
    }
    console.log(result, input, unit);
    return result.length > 0 ? result.join(' ') : `${input} ${unit}`;
};
