import axios from 'axios';
import { arrayPluck, arrayUnique, filterArray } from '../../helpers/arr';
import { transform } from '../../helpers/transform';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_constants.configs';

export const listConstants = async (params = null) => {
    const { data } = await axios.get(urls.constants, {
        params: { silent: true, ...params },
    });
    return data;
};

export const getConstants = (consts, type, filter = null) => {
    const chunks = type.split('.');
    if (chunks.length > 1) {
        consts = filterArray(consts, 'type', chunks.shift());
        consts = filterArray(consts, 'name', chunks.shift());
        return transform('options', JSON.parse(consts[0].value), 'value', 'name', filter);
    }
    const options = transform(
        'options',
        filterArray(consts, 'type', chunks[0]),
        'value',
        'name',
        filter
    );
    return options;
};

export const getConstantTypes = async () => {
    const { data } = await axios.get(urls.constants);
    return arrayUnique(arrayPluck(data, 'type'));
};

export const updateConstant = async (id, payload) => {
    const { data } = await axios.patch(buildUrl(urls.constant_details, { id }), payload);
    return data;
};

export const deleteConstant = async (id) => {
    const { data } = await axios.delete(buildUrl(urls.constant_details, { id }));
    return data;
};
