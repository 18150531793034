import axios from 'axios';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_identity.configs';

export const listUsers = async (filter = {}) => {
    const { data } = await axios.get(buildUrl(urls.users, filter));
    return data;
};

export const getUser = async (id) => {
    const { data } = await axios.get(buildUrl(urls.user_details, { id, expand: 'roles.permissions' }));
    return data;
};

export const createUserRoleDetails = async (payload) => {
    const { data } = await axios.post(urls.roles, payload);
    return data;
};

export const updateUserRoleDetails = async (payload, roleId) => {
    const { data } = await axios.patch(urls.roles + roleId + '/', payload);
    return data;
};

export const getProfiles = async (id) => {
    const { data } = await axios.get(buildUrl(urls.profiles));
    return data;
};

export const changeProfile = async (payload) => {
    const { data } = await axios.post(buildUrl(urls.choose_profile), payload);
    return data;
};
