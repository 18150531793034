import { FC } from 'react';
import { useLayout } from '../core';

const Footer: FC = () => {
    const { classes } = useLayout();
    return (
        <div
            className='footer py-4 d-flex flex-lg-column'
            id='kt_footer'>
            {/* begin::Container */}
            <div
                className={`m-10 d-flex flex-column flex-md-row align-items-center justify-content-between`}>
                {/* begin::Copyright */}
                <div className='text-gray-900 order-2 order-md-1'>
                    <span className='fw-semibold me-2'>{new Date().getFullYear()} &copy;</span>
                    <a
                        href='https://crego.io'
                        className='text-gray-800 text-hover-primary'>
                        Crego (Kiranovations Technology Pvt. Ltd.)
                    </a>
                </div>
                <div className='text-gray-900 order-2 order-md-1'>
                    <span className='text-muted fw-semibold me-2'>For any issues, contact</span>
                    <a
                        href='mailto:tech@crego.io'
                        className='text-gray-800 text-hover-primary'>
                        tech@crego.io
                    </a>
                </div>
                {/* end::Copyright */}
            </div>
            {/* end::Container */}
        </div>
    );
};

export { Footer };
