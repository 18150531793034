import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { LayoutSplashScreen } from '../../../_metronic/layout/core';
import { getLead, getLeadProgramAccounts } from '../../../app/modules/Crm/_crm.service';
import { getCompany, getCompanyConfig } from '../../../app/modules/Unr/_unr.service';
import * as authHelper from './AuthHelpers';
import { getBorrowerByToken, getUserByToken } from './_requests';

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: (auth) => {},
    currentUser: undefined,
    setCurrentUser: (user) => {},
    logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(authHelper.getAuth());
    const [currentUser, setCurrentUser] = useState();
    const saveAuth = (auth) => {
        setAuth(auth);
        if (auth) {
            authHelper.setAuth(auth);
        } else {
            authHelper.removeAuth();
        }
    };

    const logout = () => {
        saveAuth(undefined);
        setCurrentUser(undefined);
    };

    return (
        <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const AuthInit = ({ children }) => {
    const { auth, logout, setCurrentUser } = useAuth();
    const didRequest = useRef(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async (apiToken) => {
            try {
                if (!didRequest.current) {
                    const userType = localStorage.getItem('user_type');
                    const { data } =
                        userType === 'borrower'
                            ? await getBorrowerByToken()
                            : await getUserByToken(true);
                    if (data) {
                        if (data.company_id) {
                            data.company = await getCompany(data.company_id, {
                                active_services: true,
                            });
                            if (!data.company) {
                                data.company = {};
                            }
                            data.company.config = await getCompanyConfig(
                                data?.company?.owned_by || data?.company_id
                            );
                        } else {
                            data.company = { config: await getCompanyConfig() };
                        }
                        if (userType === 'borrower') {
                            data.user_type = 'borrower';
                            const tmp = {};
                            const serviceAccounts = await getLeadProgramAccounts(
                                data.lead_id,
                                data.program_id
                            );
                            Object.entries(serviceAccounts).forEach(([key, value]) => {
                                if (value?.length) {
                                    tmp[key] = value[0];
                                }
                            });
                            data.user = await getLead(data.lead_id);
                            data.service_accounts = tmp;
                        }
                        setCurrentUser(data);
                    }
                }
            } catch (error) {
                console.log(error);
                if (!didRequest.current) {
                    logout();
                }
            } finally {
                setShowSplashScreen(false);
            }

            return () => (didRequest.current = true);
        };
        if (auth && auth.access) {
            requestUser(auth.access);
        } else {
            logout();
            setShowSplashScreen(false);
        }
        // eslint-disable-next-line
    }, []);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthInit, AuthProvider, useAuth };
