/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { ROUTE_URLS } from '../../../app/consts/route_urls';
import { useAuth } from '../core/Auth';
import { sendOtp, verifyOtp } from '../core/_requests';

const LoginBorrower = () => {
    const loginSchema = Yup.object().shape({
        mobile: Yup.string().required('Mobile is required'),
    });

    const initialValues = {
        mobile: '',
        otp: Array(6).fill(''),
    };
    const [loading, setLoading] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();
    const [otpVerified, setOtpVerified] = useState(false);
    const { saveAuth } = useAuth();
    const inputRefs = useRef([]);

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            setLoading(true);
            await sendOtp(values.mobile);
        },
    });

    const handleSendOtp = async (e) => {
        e.preventDefault();
        const response = await sendOtp(formik.values.mobile);
        if (response.status) {
            setOtpSent(true);
        }
    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        const tmp = formik.values.otp;
        tmp[index] = value;
        formik.setFieldValue('otp', tmp);
        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        const { data } = await verifyOtp(formik.values.mobile, formik.values.otp.join(''));
        navigate(ROUTE_URLS.auth_programs);
        if (data.access_token) {
            saveAuth({ access: data.access_token, refresh: data.refresh_token });
            setOtpVerified(true);
        }
    };

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [otpSent]);

    return (
        <>
            {!otpVerified && !otpSent && (
                <form
                    className='form w-100'
                    onSubmit={handleSendOtp}
                    noValidate
                    id='kt_login_signin_form'>
                    {/* begin::Heading */}
                    <div className='text-center mb-10'>
                        <h1 className='text-gray-900 fw-bolder mb-3'>Sign In</h1>

                        <div className='text-gray-500 fw-semibold fs-6'>
                            Manage & View your Credit Line
                        </div>
                    </div>
                    {/* begin::Heading */}

                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                        <label className='form-label fs-6 fw-bolder text-dark'>Mobile</label>
                        <input
                            placeholder='Mobile'
                            {...formik.getFieldProps('mobile')}
                            className={clsx('form-control form-control-lg form-control-solid')}
                            type='number'
                            name='mobile'
                            min='0'
                            inputMode='numeric'
                            pattern='[0-9]*'
                        />
                        {formik.touched.mobile && formik.errors.mobile && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.mobile}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Action */}
                    <div className='text-center'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-lg btn-primary w-100 mb-5'
                            disabled={formik.isSubmitting || !formik.isValid}>
                            {!loading && <span className='indicator-label'>Send OTP</span>}
                            {loading && (
                                <span
                                    className='indicator-progress'
                                    style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        <div className='text-gray-500 text-center fw-semibold fs-6'>
                            <a
                                href='/auth/login'
                                className='link-primary'>
                                {' '}
                                Login as a Lender/Anchor
                            </a>
                        </div>
                    </div>
                </form>
            )}
            {!otpVerified && otpSent && (
                <form
                    onSubmit={handleVerifyOtp}
                    className='form w-100 mb-13'
                    noValidate>
                    <div className='text-center mb-10'>
                        <img
                            alt='Smartphone'
                            className='mh-125px'
                            src={toAbsoluteUrl('/media/svg/smartphone-2.svg')}
                        />
                    </div>
                    <div className='text-center mb-10'>
                        <h1 className='text-gray-900 mb-3'>OTP Verification</h1>

                        <div className='text-muted fw-semibold fs-5 mb-5'>
                            Enter the verification code we sent to
                        </div>

                        <div className='fw-bold text-gray-900 fs-3'>{formik.values.mobile}</div>
                    </div>

                    <div className='mb-10'>
                        <div className='fw-bold text-start text-gray-900 fs-6 mb-1 ms-1'>
                            Type your 6 digit OTP here
                        </div>

                        <div className='d-flex flex-stack'>
                            {[...Array(6)].map((_, index) => (
                                <input
                                    key={index}
                                    type='number'
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    name={`otp_${index + 1}`}
                                    data-inputmask="'mask': '9', 'placeholder': ''"
                                    maxLength='1'
                                    min='0'
                                    max='9'
                                    inputMode='numeric'
                                    pattern='[0-9]*'
                                    className='form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2'
                                    value={formik.values['otp'][index] || ''}
                                    onChange={(e) => handleInputChange(e, index)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='d-flex flex-center'>
                        <button
                            type='submit'
                            id='kt_sign_in_submit'
                            className='btn btn-lg btn-primary w-100 mb-5'
                            disabled={formik.isSubmitting || !formik.isValid}>
                            {!loading && <span className='indicator-label'>Verify OTP</span>}
                            {loading && (
                                <span
                                    className='indicator-progress'
                                    style={{ display: 'block' }}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    <div className='text-gray-500 text-center fw-semibold fs-6'>
                        <a
                            href='/auth/login'
                            className='link-primary'>
                            {' '}
                            Login as a Lender/Anchor
                        </a>
                    </div>
                </form>
            )}
            {otpVerified && <></>}
        </>
    );
};

export default LoginBorrower;
