import React from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { transform } from '../../helpers/transform';

const Svg = (props) => {
    const { icon, className } = props;
    const path = transform('icon', icon);
    return (
        <KTSVG path={path} className={className} />
    )
}

export default Svg