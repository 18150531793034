import { getValue } from './utils';

export const getArrayIndex = (array, key, value) => {
    let response = -1;
    array.forEach((item, index) => {
        if (item?.[key] === value) {
            response = index;
        }
    });
    return response;
};

export const searchArray = (array, key, value) => {
    let response = {};
    if (array && Array.isArray(array)) {
        array.forEach((item) => {
            if (getValue(item, key) === value) {
                response = item;
            }
        });
    }
    if (!Object.keys(response).length) {
        return false;
    }
    return response;
};

export const groupifyArray = (array, key) => {
    const tmp = {};
    array.forEach((row) => {
        const k = getValue(row, key);
        if (!tmp[k]) {
            tmp[k] = [row];
        } else {
            tmp[k].push(row);
        }
    });
    return tmp;
};

export const filterArray = (arr, key, value) => {
    if (!arr) return arr;
    return arr.filter(
        (item) => item[key] && value && item[key].toLowerCase() === value.toLowerCase()
    );
};

export const updateResponseArray = (arr, data, key = 'id') => {
    const tmp = [...arr];
    tmp.forEach((row, index) => {
        if (row[key] === data[key]) {
            tmp[index] = { ...arr[index], ...data };
        }
    });
    return tmp;
};

export const deleteResponseArrayItem = (arr, data, key = 'id') => {
    const tmp = [...arr];
    tmp.forEach((row, index) => {
        if (row[key] === data[key]) {
            tmp.splice(index, 1);
        }
    });
    return tmp;
};

export const arrayUnique = (arr) => {
    return arr.filter((item, pos) => arr.indexOf(item) === pos);
};

export const arrayPluck = (arr, keys) => {
    if (typeof keys === 'string') {
        keys = [keys];
    }
    const values = [];
    arr?.forEach((row) => {
        keys.forEach((key) => {
            if (row[key]) {
                values.push(row[key]);
            }
        });
    });
    return values;
};

export const objectify = (data, key = 'key') => {
    if (!data) {
        return {};
    }
    const payload = {};
    data.forEach((row) => {
        const tmp = {};
        Object.entries(row).forEach(([k, v]) => {
            if (k !== key) {
                tmp[k] = v;
            }
        });
        payload[row[key]] = tmp;
    });
    return payload;
};

export const unObjectify = (data, key = 'key') => {
    Object.entries(data).forEach(([k, v]) => {
        v[key] = k;
    });
    return Object.values(data);
};

export const sumArray = (arr) => {
    return arr.reduce((partialSum, a) => partialSum + a, 0);
};

export const chunkArray = (myArray, chunk_size) => {
    const arrayLength = myArray.length;
    const tempArray = [];
    for (let index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = myArray.slice(index, index + chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
};

export const takeOne = (value, delimiter = '.') => {
    if (typeof value === 'string') {
        value = value.split(delimiter);
    }
    const first = value[0];
    value.splice(0, 1);
    return [first, value];
};

export const deleteElement = (array, value) => {
    if(!array) {
        return [];
    }
    const index = array.indexOf(value);
    if (index !== -1) {
        array.splice(index, 1);
    }
    return array;
};

export const arrayDiff = (array1, array2) => {
    if (array2) {
        return array1.filter((element) => !array2.includes(element));
    }
    return array1;
};

export const arrayIntersection = (array1, array2) => {
    if (array2) {
        return array1.filter((value) => array2.includes(value));
    }
    return array1;
};

export const compareArray = (array1, array2) => {
    const set2 = new Set(array2);
    for (let element of array1) {
        if (set2.has(element)) {
            return true;
        }
    }
    return false;
};
