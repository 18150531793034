import { useEffect } from 'react';

const useTitle = (title, overwrite = true) => {
    useEffect(() => {
        const suffix = 'Crego ☄️';
        if (overwrite) {
            document.title = title ? `${title} | ${suffix}` : suffix;
        }
    }, [title, overwrite]);
};

export default useTitle;
