import axios from 'axios';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_disbursal.config';

export const getAccount = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.account_details, {
            id,
        })
    );
    return data;
};

export const listTopups = async (params) => {
    const { data } = await axios.get(buildUrl(urls.topups, params));
    return data;
};

export const listLoans = async (params) => {
    const { data } = await axios.get(buildUrl(urls.loans, params));
    return data;
};

export const getTopup = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.topup_details, {
            id,
            expand: ['same_topup', 'loan_data', 'loan'].join(','),
        })
    );
    return data;
};

export const listPayouts = async (params) => {
    const { data } = await axios.get(urls.payouts, {
        params,
    });
    return data;
};

export const getPayout = async (id) => {
    const { data } = await axios.get(buildUrl(urls.payout_details, { id }));
    return data;
};

export const getBorrower = async (id) => {
    const { data } = await axios.get(buildUrl(urls.borrower_details, { id }));
    return data;
};
