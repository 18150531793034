const BASE_URL =
    (process.env.REACT_APP_API_ROOT_IDENTITY || process.env.REACT_APP_API_ROOT_DEFAULT) +
    '/identity/';

export const urls = {
    login: BASE_URL + 'auth/token/',
    refresh: BASE_URL + 'auth/token/refresh',
    auth_ping: BASE_URL + 'auth/ping/',

    send_otp: BASE_URL + 'auth/borrower/send_otp/',
    borrower_ping: BASE_URL + 'auth/borrower/ping/',
    verify_otp: BASE_URL + 'auth/borrower/verify_otp/',
    profiles: BASE_URL + 'auth/borrower/profiles/',
    choose_profile: BASE_URL + 'auth/borrower/choose_profile/',

    users: BASE_URL + 'users/',
    user_details: BASE_URL + 'users/:id/',
    user_reset_password: BASE_URL + 'users/:id/reset_password',
    user_details_toggle_status: BASE_URL + 'users/:id/toggle_status',

    roles: BASE_URL + 'roles/',
    role_details: BASE_URL + 'roles/:id/',

    permissions: BASE_URL + 'permissions/',
    permission_details: BASE_URL + 'permissions/:id',
};
