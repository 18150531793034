import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'
import { getCompany } from '../../../app/modules/Unr/_unr.service'
import { useAuth } from '../core/Auth'
import { getUserByToken, register } from '../core/_requests'

const initialValues = {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
}

export function Registration() {
    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser } = useAuth()
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            try {
                const { data: auth } = await register(
                    values.email,
                    values.firstname,
                    values.lastname,
                    values.password,
                    values.changepassword
                )
                saveAuth(auth)
                const { data: user } = await getUserByToken()
                if (user.company_id) {
                    user.company = await getCompany(user.company_id)
                }
                setCurrentUser(user)
            } catch (error) {
                saveAuth(undefined)
                setStatus('The registration details is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='mb-10 text-center'>
                {/* begin::Title */}
                <h1 className='text-dark mb-3'>Create an Account</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-400 fw-bold fs-4'>
                    Already have an account?
                    <Link to='/auth/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
                        Forgot Password ?
                    </Link>
                </div>
                {/* end::Link */}
            </div>
            {/* end::Heading */}

            <div className='d-flex align-items-center mb-10'>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
                <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
                <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            </div>

            {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            )}

            <div className='row fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                <div className='col-xl-12'>
                    <input
                        placeholder='Name'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('name')}
                        className='form-control form-control-lg form-control-solid'
                    />
                    {formik.touched.firstname && formik.errors.firstname && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.firstname}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className='form-control form-control-lg form-control-solid'
                />
            </div>
            <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Username</label>
                <input
                    placeholder='Username'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('username')}
                    className={clsx('form-control form-control-lg form-control-solid')}
                />
            </div>
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                    <div className='position-relative mb-3'>
                        <input
                            type='password'
                            placeholder='Password'
                            autoComplete='off'
                            {...formik.getFieldProps('password')}
                            className='form-control form-control-lg form-control-solid'
                        />
                    </div>
                </div>
            </div>
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.values.acceptTerms}
                >
                    {!loading && <span className='indicator-label'>Submit</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-primary w-100 mb-5'
                    >
                        Cancel
                    </button>
                </Link>
            </div>
            {/* end::Form group */}
        </form>
    )
}
