import React from 'react';
import { useAuth } from '../../../../_mApp/auth';
import { transform } from '../../../../app/helpers/transform';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MenuItem } from './MenuItem';
import { WEBSITE_NAV } from './nav';

export function MenuInner() {
    const { currentUser } = useAuth();

    const nav = WEBSITE_NAV(currentUser, currentUser?.user_type);

    const checkServices = (item) => {
        return (
            !item.service ||
            !currentUser?.company ||
            currentUser?.company?.active_services?.includes(item.service)
        );
    };

    const checkCompany = (item) => {
        return (
            (item.company === currentUser?.type ||
                (!item.company && !item.su) ||
                (!currentUser?.company_id && !item.companyOnly)) &&
            (!item.companyOnly || (item.companyOnly && currentUser?.company_id))
        );
    };

    const checkPermissions = (item) => {
        if (!item?.permissions) {
            return true;
        }
        let response = false;
        item.permissions.forEach((permission) => {
            if (currentUser?.permissions?.indexOf(permission) !== -1) {
                response = true;
            }
        });
        return response;
    };

    return (
        <>
            {nav?.map((item, index) => {
                return (
                    <React.Fragment key={`item_${index}`}>
                        {checkServices(item) && checkCompany(item) && checkPermissions(item) && (
                            <>
                                {item.children && (
                                    <MenuInnerWithSub
                                        to={item.url || ''}
                                        title={item.label || ''}
                                        icon={transform('icon', item.icon)}
                                        hasArrow={true}
                                        menuPlacement='bottom-start'
                                        menuTrigger={`{default:'click', lg: 'hover'}`}>
                                        {item?.children?.map((subitem, subIndex) => {
                                            return (
                                                <React.Fragment
                                                    key={`item_${index}_subitem_${subIndex}`}>
                                                    {(subitem.company === currentUser?.type ||
                                                        (!subitem.company && !subitem.su) ||
                                                        (!currentUser?.company_id &&
                                                            !subitem.companyOnly)) &&
                                                        checkPermissions(subitem) && (
                                                            <>
                                                                {subitem.type === 'content' ? (
                                                                    <div className='menu-item'>
                                                                        <div className='menu-content'>
                                                                            <div className='menu-section text-muted ms-10'>
                                                                                {subitem.label}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <MenuItem
                                                                        disabled={subitem.disabled}
                                                                        to={subitem.url}
                                                                        title={subitem.label}
                                                                        icon={subitem.icon}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </MenuInnerWithSub>
                                )}
                                {!item.children && (
                                    <MenuItem
                                        icon={item.icon}
                                        disabled={item.disabled}
                                        to={item.url || ''}
                                        title={item.label}
                                    />
                                )}
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
}
