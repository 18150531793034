import { bindActionCreators } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { AuthInit } from '../_mApp/auth';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import './App.scss';
import { alertError, toastError } from './helpers/alert';
import { isJson, metaTitle } from './helpers/str';
import { transform } from './helpers/transform';
import { fixId, getQueryParams } from './helpers/utils';
import { listConstants } from './modules/Constants/_constants.service';
import { creators } from './state';
import { arrayPluck } from './helpers/arr';
import PopupView from './components/organisms/PopupView';
import { VERSIONS } from './dict/versions';
import useTitle from './hooks/useTitle';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const useConstructor = (callBack = () => {}) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack();
    setHasBeenCalled(true);
};

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.data) {
            response.data = fixId(response.data);
        }

        if (response?.data?.errors) {
            const title = response?.data?.message;
            const message = arrayPluck(response?.data?.errors, 'error');
            alertError(message, title);
        }

        if (Array.isArray(response.data)) {
            response.data.forEach((row) => {
                if (row?._id) {
                    row.id = row._id;
                }
            });
        } else if (response?.data?._id) {
            response.data.id = response.data._id;
        } else if (response?.data?.results) {
            response.data.results.forEach((row) => {
                if (row?._id) {
                    row.id = row._id;
                }
            });
        }
        return response;
    },
    (error) => {
        const safeKeys = ['missing_fields'];
        let message = 'An error occurred, please try again later.';
        let title = 'Error';
        const { response } = error;
        let { queryParams } = getQueryParams(error?.config?.url);
        queryParams = { ...queryParams, ...error.config.params };

        if (error && response && response.data && isJson(response.data)) {
            let jsonData = response.data;

            if (response.data instanceof ArrayBuffer) {
                const responseData = new TextDecoder().decode(response.data);
                jsonData = JSON.parse(responseData);
            }

            if (jsonData?.description) {
                title = jsonData.message || null;
                if (Array.isArray(jsonData.description)) {
                    message = {};
                    jsonData.description.forEach((desc) => {
                        safeKeys.forEach((key) => {
                            if (response.data.description[key]) {
                                message = {
                                    ...message,
                                    [key]: response.data.description[key].join(', '),
                                };
                            }
                        });
                    });
                } else {
                    safeKeys.forEach((key) => {
                        if (jsonData.description[key]) {
                            message = {
                                ...message,
                                [key]: jsonData.description[key].join(', '),
                            };
                        }
                    });
                }
            } else if (jsonData.errors) {
                title = jsonData.message;
                message = jsonData.errors;
            } else if (jsonData?.error?.message) {
                message = jsonData.error.message;
            } else if (jsonData.message) {
                message = jsonData.message;
            } else if (jsonData) {
                const keys = Object.keys(jsonData);
                if (Array.isArray(jsonData[keys[0]])) {
                    message = transform('beautify', keys[0]) + ': ' + jsonData[keys[0]][0];
                } else {
                    message = transform('beautify', keys[0]) + ': ' + jsonData[keys[0]];
                }
            }
            if (!queryParams.silent) {
                if (response.config.method.toLowerCase() === 'get') {
                    toastError(title + ': ' + message);
                } else {
                    alertError(message, title);
                }
            }
        }

        return Promise.reject(error);
    }
);

const App = () => {
    // const authInfo = useSelector(state => state.authInfo)
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    const dispatch = useDispatch();
    const { setConstants } = bindActionCreators(creators, dispatch);
    const [changeLog, setChangeLog] = useState({});
    const [welcomeView, setWelcomeView] = useState(true);
    const location = useLocation();

    const initConstants = async () => {
        const data = await listConstants();
        setConstants(data);
    };

    const initChangeLog = () => {
        const log = VERSIONS;
        const currentVersion = localStorage.getItem('crego_app_version');
        if (!currentVersion) {
            localStorage.setItem('crego_app_version', log[0]?.version);
        } else {
            setChangeLog(log[0]);
        }
    };

    useConstructor(async () => {
        if (localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_AUTH || 'crego-auth')) {
            await initConstants();
        }
        initChangeLog();
    });

    const handleChangeLogHide = () => {};

    useEffect(() => {
        const pathname = location.pathname;
        ReactGA.send({ hitType: 'pageview', page: pathname });
    }, [location]);

    useTitle(metaTitle(location.pathname));

    return (
        <>
            <ToastContainer
                position='top-center'
                autoClose={3000}
                newestOnTop={true}
                closeOnClick={true}
                theme='colored'
            />
            <PopupView
                title={`Updated to ${changeLog.version}`}
                show={false}
                onHide={handleChangeLogHide}></PopupView>
            <Suspense fallback={<LayoutSplashScreen />}>
                <I18nProvider>
                    <LayoutProvider>
                        <AuthInit>
                            <Outlet />
                            <MasterInit />
                        </AuthInit>
                    </LayoutProvider>
                </I18nProvider>
            </Suspense>
        </>
    );
};

export { App };
