import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { checkIsActive, KTIcon, KTSVG } from '../../../helpers';
import { transform } from '../../../../app/helpers/transform';

type Props = {
    to: string;
    title: string;
    icon?: string;
    fontIcon?: string;
    hasArrow?: boolean;
    hasBullet?: boolean;
    disabled?: boolean;
};

const MenuItem: React.FC<Props> = ({
    to,
    title,
    icon,
    fontIcon,
    hasArrow = false,
    hasBullet = false,
    disabled = false,
}) => {
    const { pathname } = useLocation();

    return (
        <div className='menu-item'>
            <Link
                className={clsx('menu-link py-3 px-5', {
                    'bg-danger': checkIsActive(pathname, to) && icon,
                    active: checkIsActive(pathname, to) && !icon,
                })}
                to={to}>
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}

                {icon && (
                    <span className={`menu-icon ${!title ? 'me-0' : ''}`}>
                        <div className='svg-icon cursor-pointer'>
                            <KTSVG
                                className={`text-white`}
                                path={transform('icon', icon)}
                            />
                        </div>
                    </span>
                )}

                {fontIcon && (
                    <span className='menu-icon'>
                        <i className={clsx('bi fs-3', fontIcon)}></i>
                    </span>
                )}

                <span className={`menu-title ${disabled ? 'text-muted' : ''}`}>{title}</span>

                {hasArrow && <span className='menu-arrow'></span>}
            </Link>
        </div>
    );
};

export { MenuItem };
