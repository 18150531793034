export const trasformMapping = {
    status: 'beautify',
    type: 'beautify',
    product_name: 'beautify',
    is_active: 'beautify',
    business_type: 'beautify',
    business_sector: 'beautify',
    industry_type: 'beautify',
    sub_industry: 'beautify',
    excess_payment: 'beautify',
    tenure_starts_at: 'beautify',
    interest_starts: 'beautify',
    account_type: 'beautify',
    freezed: 'beautify',
    subvention_value_type: 'beautify',
    tenure_period_type: 'beautify',
    tenure_period: 'beautify',
    roi_period: 'beautify',
    cashback_payment_to: 'beautify',
    cashback_use_as: 'beautify',
    cashback_release_at: 'beautify',
    cashback_deduction_type: 'beautify',
    holdback_return_payment_to: 'beautify',
    freeze_accounting_interval: 'beautify',
    product_type: 'beautify',
    default_settlement_order: 'beautify',
    dpd_starts_from: 'beautify',

    line_limit: 'amount',
    cashback_deductions: 'amount',
    subvention_ack: 'amount',
    originator_subvention_ack: 'amount',
    co_lender_subvention_ack: 'amount',
    interest_accrual: 'amount',
    record_principal: 'amount',
    originator_record_principal: 'amount',
    co_lender_record_principal: 'amount',
    record_interest: 'amount',
    originator_record_interest: 'amount',
    co_lender_record_interest: 'amount',
    cashback_available: 'amount',
    originator_cashback_available: 'amount',
    co_lender_cashback_available: 'amount',
    cashback_adjusted: 'amount',
    originator_cashback_adjusted: 'amount',
    co_lender_cashback_adjusted: 'amount',
    cashback_released: 'amount',
    originator_cashback_released: 'amount',
    co_lender_cashback_released: 'amount',

    date_of_incorporation: 'date',
    invoice_date: 'date',
    invoice_approved_at: 'dateFromEpoch',

    created_at: 'dateTimeFromEpoch',
    updated_at: 'dateTimeFromEpoch',
    scheduled_at: 'dateTimeFromEpoch',
    line_activation_date: 'dateFromEpoch',
    line_expiry_date: 'dateFromEpoch',
    loan_start_date: 'dateFromEpoch',
    due_date: 'dateFromEpoch',
    subvention_end_date: 'dateFromEpoch',
    disbursement_date: 'dateFromEpoch',
    transaction_date: 'dateFromEpoch',
    date: 'dateFromEpoch',
    value_date: 'dateFromEpoch',
    bank_date: 'dateFromEpoch',
    back_date_marked_on: 'dateFromEpoch',
    settled_at: 'dateFromEpoch',
};

export const hideWhenNull = ['lender_ref_id', 'anchor_ref_id'];

export const ignoredKeys = [
    'is_deleted',
    'is_editable',

    '_id',
    'id',
    'company_id',
    'bureau_id',
    'program_id',
    'crm_lead_id',
    'lead_id',
    'disburse_account_id',
    'disburse_topup_id',

    'program_lender',

    'is_primary',
    'updated_at',
    'created_at',
    'created_by',

    'topup',
    'loan',

    'account_id',
    'common_lead',
    'borrower_lead_id',
];

export const tabularizeIgnore = [
    ...ignoredKeys,
    'owned_by',
    'routing_response_error',
    'routing_response_type',
    'routing_response_value',
    'routing_program_id',
    'program'
];
