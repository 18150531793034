import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../_mApp/auth';
import { ROUTE_URLS } from '../../../../app/consts/route_urls';
import { beautify } from '../../../../app/helpers/str';
import { toAbsoluteUrl } from '../../../helpers';

const HeaderUserMenu: FC = () => {
    const { currentUser, logout } = useAuth();
    return (
        <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
            data-kt-menu='true'
            data-popper-placement='bottom-start'>
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                        />
                    </div>
                    {currentUser?.type === 'borrower' ? (
                        <div className='d-flex flex-column'>
                            <div className='fw-bolder fs-5'>
                                <div>{currentUser?.user?.business_entity?.name}</div>
                                <div className='fs-8 text-muted'>
                                    Ref ID: {currentUser?.user?.ref_id}
                                </div>
                                <span className='fs-8 text-muted'>
                                    {beautify(currentUser?.user?.business_entity?.primary_mobile)}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className='d-flex flex-column'>
                            <div className='fw-semibold fs-5'>
                                <div>
                                    {currentUser?.name}{' '}
                                    <span className='fs-8 text-primary'>
                                        @{currentUser?.username}
                                    </span>
                                </div>
                                <span className='fw-semibold fs-8 text-gray-500'>
                                    {currentUser?.company?.name}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {currentUser?.user_type !== 'borrower' && (
                <div className='menu-item px-5 my-1'>
                    <Link
                        to={`${ROUTE_URLS.users}${currentUser?.id}`}
                        className='menu-link px-5'>
                        Account Settings
                    </Link>
                </div>
            )}
            {currentUser?.user_type === 'borrower' && (
                <div className='menu-item px-5'>
                    <Link
                        to={ROUTE_URLS.switch_program}
                        className='menu-link px-5'>
                        Switch Program
                    </Link>
                </div>
            )}
            <div className='menu-item px-5'>
                <Link
                    to={ROUTE_URLS.logout}
                    className='menu-link px-5'>
                    Sign Out
                </Link>
            </div>
        </div>
    );
};

export { HeaderUserMenu };
