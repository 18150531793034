import { useEffect } from 'react';
import { Navigate, Routes } from 'react-router-dom';
import { ROUTE_URLS } from '../../app/consts/route_urls';
import { useAuth } from './core/Auth';

export function Logout() {
    const { logout } = useAuth();
    useEffect(() => {
        logout();
        window.location.href = ROUTE_URLS.auth_login;
    }, []);

    return <></>;
}
