export const DICT = {
    en: {
        id: 'ID',
        utm: 'UTM',
        nach: 'NACH',
        pan: 'PAN',
        nsdl: 'NSDL',
        gst: 'GST',
        cgst: 'CGST',
        sgst: 'SGST',
        igst: 'IGST',
        tds: 'TDS',
        api: 'API',
        kyc: 'KYC',
        bre: 'BRE',
        ckyc: 'CKYC',
        pwa: 'PWA',
        ntc: 'NTC',
        dpd: 'DPD',
        dpds: 'DPDs',
        pdc: 'PDC',
        spdc: 'SPDC',
        abb: 'ABB',
        pf: 'Processing Fee',
        pg: 'Payment Gateway',
        ifsc: 'IFSC',
        micr: 'MICR',
        utr: 'UTR',
        nbfc: 'NBFC',
        sms: 'SMS',
        gl: 'GL',

        digilocker: 'DigiLocker',
        whatsapp: 'WhatsApp',
        email: 'E-Mail',
        esign: 'eSign',

        unique_lead_id: 'Unique Lead Identifier',

        dob: 'Date of Birth',

        gst_no: 'GST Number',
        gstin: 'GST Number',
        pan_no: 'PAN Number',

        cin: 'CIN',
        rbi: 'RBI',
        doc: 'Document',

        nin: 'Not in',

        aws: 'AWS',
        crm: 'CRM',
        los: 'LOS',
        cpi: 'Company Plugin ID',
        url: 'URL',
        html: 'HTML',

        // Karza
        panno: 'PAN Number',
        Rgdt: 'Registration Date',
        pradr: 'Principal Address',
        tradeNam: 'Trade Name',
        stjCd: 'State Jurisdiction Code',
        stj: 'State Jurisdiction',
        ctjCd: 'Central Jurisdiction Code',
        ctj: 'Central Jurisdiction',
        dty: 'Taxpayer Type',
        cmpRt: 'Compliance Rating',
        cxdt: 'Date of Cancellation of Registration',
        ctb: 'Business Type',
        sts: 'Status',
        lgnm: 'Legal Name',
        nba: 'Nature of Business',
        adadr: 'Additional Place Address',
        Accno: 'Account Number',
        Chequeno: 'Cheque Number',
        Bankdetails: 'Bank Details',

        gst_source_gst_input_match: 'GST(Govt. DB) <> Input',
        gst_source_pan_source_match: 'GST(Govt. DB) <> PAN(Govt. DB)',
        bank_source_pan_source_match: 'Bank(Penny Drop) <> PAN(Govt. DB)',
        bank_source_gst_source_match: 'Bank(Penny Drop) <> GST(Govt. DB)',
        pan_source_aadhaar_ocr_match: 'PAN(Govt. DB) <> Aadhaar OCR',
        pan_source_pan_ocr_match: 'PAN(Govt. DB) <> PAN OCR',
        pan_ocr_aadhaar_ocr_match: 'PAN OCR <> Aadhaar OCR',
        pan_source_name_input_match: 'PAN(Govt. DB) <> Name',
        pan_source_dob_input_match: 'PAN(Govt. DB) <> DOB',
        pan_ocr_father_input_match: "PAN OCR <> Father's Name",
        aadhaar_ocr_address_input_match: 'Aadhaar OCR <> Address',

        roi: 'ROI',
        sod: 'Start of Day',
        eod: 'End of Day',
        msa: 'MSA',
        npa: 'NPA',

        subvention: 'Upfront Interest',
        subvention_ack: 'Upfront Interest Realised',

        P: 'Principal',
        NI: 'Normal Interest',
        OI: 'Overdue Interest',
        PI: 'Penal Interest',
        rm: 'RM',

        sbi: 'State Bank of India (SBI)',

        scf: 'SCF',
        emi: 'EMI',

        loan_data_sets: 'Loan Data',

        otp: 'OTP',
        fathers: "Father's",

        fldg: 'FLDG',
        foir: 'FOIR',

        huf: 'HUF',
        aop: 'AOP',

        itr: 'ITR',
        pdd: 'PDD',

        ocr: 'OCR',

        get: 'GET',
        post: 'POST',
        patch: 'PATCH',
        put: 'PUT',
        options: 'OPTIONS',
        head: 'HEAD',
        delete: 'DELETE',
    },
};

export const ROI_PERIOD = {
    years: 'per annum',
    months: 'per month',
    weeks: 'per week',
    days: 'per day',
};
